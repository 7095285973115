/** @jsx jsx */
import { jsx } from 'theme-ui';
import { Box, Flex, Button } from 'rebass';

import { H2, H3, H4, P } from '../../components/Text';
import { Layout } from '../../layouts/Layout';
import { useTranslation } from '../../i18n/TranslationContext';
import { PartnerLayout } from '../../layouts/PartnerLayout';
import { Container } from '../../components/Container';

const Section: React.FC = ({ children }) => (
  <Box sx={{ my: [4, 6], display: 'flex', flexDirection: ['column', 'row'] }}>
    {children}
  </Box>
);

const sponsorshipDeckURL =
  'https://drive.google.com/file/d/1EeDCLNhHhmVchtis1jUPPMUxGiiQatbW/view?usp=sharing';
const volunteerURL = 'http://bit.ly/Interaction21Volunteer';

const PartnerWithUsPage = () => {
  return (
    <PartnerLayout>
      <Container>
        <Box
          sx={{
            height: 426,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <H2 sx={{ mb: [3, 4], textAlign: 'center', maxWidth: 700 }}>
            Collectively, we can make a difference in these perilous times.
          </H2>
        </Box>
        {/* Sponsor section  */}
        <Section css={{ flexDirection: 'column' }}>
          <H3 sx={{ flex: 2 }}>Become a partner</H3>
          <Box sx={{ flex: 3, display: 'flex', flexDirection: 'column' }}>
            <H4>Connect with designers at the discipline’s premier event</H4>
            <P>
              Interaction21 will offer practitioners and companies the
              opportunity to reflect on how we are shaping the world moving
              forward, by building bridges across cultures, disciplines and
              industries to discover shared directions and goals.
              <Flex>
                <Button
                  as="a"
                  href="mailto:sponsorship@ixda.org"
                  sx={{ mt: 3 }}
                >
                  Get in touch →
                </Button>
              </Flex>
            </P>
            <H4>Why partner with us?</H4>
            <P sx={{ fontWeight: 'bold' }}>
              IxDA is renowned for our impressive conferences — a tradition made
              possible thanks to our partners. Interaction 21 is a key
              opportunity to reach the international interaction design
              community and its stakeholders.
            </P>
            <P>
              The narratives we hope to establish at the conference will
              resonate throughout the year, generating thousands of new
              connections and advancing the overall interaction design practice
              worldwide.
            </P>
            <P>
              Interaction has a track record of attracting 1,500 people to our
              in-person conference, and we are anticipating an equally diverse
              audience that should exceed this number. Our attendees are spread
              across many design disciplines: interaction designers, user
              experience consultants, product managers, academics, but also,
              thought leaders on user experience and VPs of Fortune 100
              companies and forward-thinking nonprofits.
            </P>
            <P>
              New this year, we’re implementing a “1:1 Program” – matching
              tickets included in your package with free tickets to provide
              access for someone else unable to cover the registration fee.
            </P>
            <H4>Interaction 21 packages are moving quickly.</H4>
            <P>
              Opportunities include social events, workshops, evening programs,
              and exhibit spaces. Contact us at{' '}
              <a
                sx={{ variant: 'variants.link' }}
                href="mailto:sponsorship@ixda.org"
              >
                sponsorship@ixda.org
              </a>
              .
            </P>
            <Flex>
              <Button
                as="a"
                target="_blank"
                href={sponsorshipDeckURL}
                sx={{ mt: 3 }}
              >
                Download the kit (PDF, 112KB)
              </Button>
            </Flex>
          </Box>
        </Section>
        {/* Volunteer section  */}
        <Section>
          <H3 sx={{ flex: 2 }}>Volunteer with us</H3>
          <Box sx={{ flex: 3, display: 'flex', flexDirection: 'column' }}>
            <P>
              Are you interested in being in the thick of the action with a crew
              of dedicated folks? If so, we'd love to hear from you! Please fill
              out our{' '}
              <a
                href={volunteerURL}
                target="_blank"
                sx={{ variant: 'variants.link' }}
              >
                volunteer application form
              </a>{' '}
              and someone from our team will be in touch.
            </P>
            <Flex>
              <Button as="a" href={volunteerURL} target="_blank" sx={{ mt: 3 }}>
                Volunteer with us ↗
              </Button>
            </Flex>
          </Box>
        </Section>
      </Container>
    </PartnerLayout>
  );
};

export default PartnerWithUsPage;
